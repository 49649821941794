@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"






















































































































































































.exam-item
  box-sizing: border-box
  color: $color-ink
  position: relative
  +anim(200ms)

  +mq-s
    max-width: 320px

  &.--disabled
    pointer-events: none

  &__header
    display: flex
    align-items: center
    margin-bottom: 16px
    position: sticky
    top: 0
    background: #fafafa
    padding: 4px 0
    z-index: 10

    +mq-m
      justify-content: space-between

  &__statement

    img, table, td
      object-fit: contain
      object-position: center center
      max-width: 100% !important
      cursor: zoom-in

      +mq-s
        width: auto !important
        height: auto !important

    p, span
      letter-spacing: 0.64px !important
      line-height: 1.4
      -webkit-user-select: none
      -moz-user-select: none
      user-select: none
      -webkit-margin-before: 1em
      -webkit-margin-after: 1em
      -webkit-margin-start: 0px
      -webkit-margin-end: 0px

      +mq-s
        text-align: left !important
        letter-spacing: .16px !important
        margin-left: 0 !important
        margin-right: 0 !important

  &__options
    margin-top: 48px

    +mq-s
      margin-top: 24px

  &:hover
    .exam-item__toolbar
      +anim(200ms)
      opacity: 1
      transform: scale(1)
      transform-origin: top right
