@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"










































































































































































.exam-timer
  display: flex
  justify-items: flex-start
  margin: 0 10px

  &:hover
    cursor: default

  &__content-timer
    background: transparent
    font-size: 28px
    position: relative

    display: flex
    flex-direction: row
    justify-content: center
    padding-left: 28px

    height: 35px
    width: 35px

    border: 3px solid $color-white
    border-color: $color-white
    border-radius: 30px

    transition: width 1.2s ease, color .3s linear

    +mq-m
      height: 25px
      width: 25px
      margin: -6px 10px 0 0
      font-size: 21px
      padding-left: 18px
      border-width: 1.5px

      border-color: #333a56

    &__time-left
      transition: opacity 0.2s linear

      line-height: 35px
      opacity: 0

      +mq-m
        line-height: 25px

      &.--timer
        transition: 0.7s opacity 0.5s ease-out
        opacity: 1

      small
        font-size: 18px
        padding: 0 2px

    &__icon
      transition: transform 1.2s ease
      width: 35px
      height: 35px
      position: absolute
      left: -3px
      top: -3px

      +mq-m
        width: 25px
        height: 25px
        left: -1px
        top: -2px

      +mq-s
        width: 25px
        height: 25px
        left: -2px
        top: -1px

      &.--animate
        transform: rotate( 1turn )

    &.--active
      width: 145px

      +mq-m
        width: 115px

  .--notification
    color: $color-warning
    border-color: $color-warning
